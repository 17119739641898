.l-global {
    display: grid;

    height: 100vh;

    grid-template-columns: 250px 1fr auto;

    grid-template-rows: min-content 1fr min-content;

    grid-template-areas: "brand header-a header-b"
                         "menu main main"
                         "menu footer-a footer-b";

    .brand {
        grid-area: brand;
    }

    .hamburger {
        display: none;
    }

    .menu {
        grid-area: menu;
    }

    .l-global-header-a {
        grid-area: header-a;
    }
    .l-global-header-b {
        grid-area: header-b;
    }

    .main {
        grid-area: main;
    }

    .l-global-footer-a {
        grid-area: footer-a;
    }
    .l-global-footer-b {
        grid-area: footer-b;
    }


    @include media-breakpoint-down(md) {

        position: relative;

        grid-template-columns: 1fr auto;
        grid-template-rows: min-content 1fr min-content;
        grid-template-areas: "brand hamburger"
                     "main main"
                     "header-b footer-b";

        .brand {
            z-index: 10;
        }

        .hamburger {
            z-index: 9;
            display: block;
        }

        .menu {
            position: fixed;
            z-index: 9;
            display: none;

            top: 0;
            left: 0;
            width: 250px;
            height: 100vh;

            padding-top: 100px;
        }

        .menu-is-open & .menu {
            display: block;
        }

        .menu-is-open & .main:before {
            content:'';
            background:rgba(0,0,0,0.4);
            position:absolute;
            z-index: 8;
            top:0;
            left:0;
            right:0;
            bottom:0;
        }
    }
}



@media print {
    body {
        background: transparent;
    }

    .l-global {

        display: block;
        height: auto;

        .brand, .menu, .header, .footer, .hamburger {
            display: none;
        }

        .main {
            padding: 0;
            background: transparent;
        }
    }
}
